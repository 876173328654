<template>
  <div class="productContent">
    <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">信贷需求详情</div>
    <p class="title">企业信贷需求信息</p>
    <div class="card">
        <div class="catalog">
            <p>需求编号</p>
            <p>{{requirementDetail.requireId}}</p>
        </div>
        <div class="catalog">
            <p>币种</p>
            <p>{{requirementDetail.currencyType}}</p>
        </div>
        <div class="catalog">
            <p>贷款金额(万元)</p>
            <p>{{requirementDetail.loanAmt}}</p>
        </div>
        <div class="catalog">
            <p>贷款期限(月)</p>
            <p>{{requirementDetail.loanPeriod}}</p>
        </div>
        <div class="catalog">
            <p>担保方式</p>
            <p>{{ replaceGua(requirementDetail.guaType)}}</p>
        </div>
        <div class="catalog">
            <p>信贷需求种类</p>
            <p>{{ replaceCredit(requirementDetail.creditReqType)}}</p>
        </div>
        <div class="catalog">
            <p>可接受利率水平</p>
            <p>{{requirementDetail.rateLevel=== '' ? '优惠利率' : requirementDetail.rateLevel}}</p>
        </div>
        <div class="catalog">
            <p>具体用途描述</p>
            <p>{{requirementDetail.useDescr}}</p>
        </div>   
        <div class="catalog">
            <p>提交人</p>
            <p>{{requirementDetail.applicationUserDescr}}</p>
        </div>
        <div class="catalog">
            <p>提交时间</p>
            <p>{{requirementDetail.applicationTime}}</p>
        </div>
        <div class="catalog">
            <p>性质</p>
            <p>{{requirementDetail.orientRequireFlag === '0' ? '公开' : '不公开'}}</p>
        </div>
        <div class="catalog">
            <p>贷款银行</p>
            <p><span v-for="item in requirementDetail.loanBankNameList" :key="item"> {{item.orgName}}<br> </span> </p>
        </div>         
    </div>
    <p class="title">企业基本信息</p>
    <div class="card">
        <div class="catalog">
            <p>企业名称</p>
            <p>{{requirementDetail.enterName}}</p>
        </div>
        <div class="catalog">
            <p>企业性质</p>
            <p>{{ replaceEnterProperty(requirementDetail.enterProperty)}}</p>
        </div>
        <div class="catalog">
            <p>所属省</p>
            <p>{{requirementDetail.provinceName}}</p>
        </div>   
        <div class="catalog">
            <p>所属市</p>
            <p>{{requirementDetail.cityName}}</p>
        </div>
        <div class="catalog">
            <p>所属区</p>
            <p>{{requirementDetail.districtName}}</p>
        </div>   
        <div class="catalog">
            <p>成立日期</p>
            <p>{{requirementDetail.foundDate}}</p>
        </div>
        <div class="catalog">
            <p>人数</p>
            <p>{{requirementDetail.peopleCnt}}</p>
        </div>
        <div class="catalog">
            <p>规模</p>
            <p>{{ replaceScale(requirementDetail.scale)}}</p>
        </div>
        <div class="catalog">
            <p>所属行业</p>
            <p>{{ replaceIndustry(requirementDetail.involveIndustry)}}</p>
        </div>   
        <div class="catalog">
            <p>主营业务</p>
            <p>{{requirementDetail.mainBusiness}}</p>
        </div>
        <div class="catalog">
            <p>法定代表人</p>
            <p>{{requirementDetail.legalName}}</p>
        </div>   
        <div class="catalog">
            <p>法定代表人证件号码</p>
            <p>{{replaceCertificate(requirementDetail.legalCertiType)}}-{{requirementDetail.legalCertiNum}}</p>
        </div>
         <div class="catalog">
            <p>总经理</p>
            <p>{{requirementDetail.generalManagerName}}</p>
        </div>   
        <div class="catalog">
            <p>总经理电话号码</p>
            <p>{{requirementDetail.generalManagerTelNum}}</p>
        </div>
        <div class="catalog">
            <p>财务总监</p>
            <p>{{requirementDetail.financialOfficerName}}</p>
        </div>   
        <div class="catalog">
            <p>财务总监电话号码</p>
            <p>{{requirementDetail.financialOfficerTelNum}}</p>
        </div>
        <div class="catalog">
            <p>联系人姓名</p>
            <p>{{requirementDetail.contactsName}}</p>
        </div>
        <div class="catalog">
            <p>联系人手机号码</p>
            <p>{{requirementDetail.contactsPhone}}</p>
        </div>
    </div>
    <p class="title">企业注册登记信息</p>
    <div class="card">
        <div class="catalog">
            <p>同一社会信用代码</p>
            <p>{{requirementDetail.uniscid}}</p>
        </div>
        <div class="catalog">
            <p>用电客户编号</p>
            <p>{{requirementDetail.useElectroAccount}}</p>
        </div>
        <div class="catalog">
            <p>用水户口</p>
            <p>{{requirementDetail.useWaterAccount}}</p>
        </div>
        <div class="catalog">
            <p>主要结算银行</p>
            <p>{{requirementDetail.principalAccountBank}}</p>
        </div>
        <div class="catalog">
            <p>基本户开户行</p>
            <p>{{requirementDetail.basicAccountOpenBank}}</p>
        </div>
        <div class="catalog">
            <p>企业海关注册号</p>
            <p>{{requirementDetail.enterCustomsRegNum}}</p>
        </div>
        <div class="catalog">
            <p>排放污染物许可证编号</p>
            <p>{{requirementDetail.pollDischPermitNum}}</p>
        </div>
        <div class="catalog">
            <p>危险化学品生产经营许可证证书号</p>
            <p>{{requirementDetail.hazardousChemProduBusiLiceNo}}</p>
        </div>   
    </div>
    <p class="title">企业经营信息</p>
    <div class="card">
        <div class="catalog">
            <p>上年销售收入(万元)</p>
            <p>{{requirementDetail.salesRevenueLastYear}}</p>
        </div>
        <div class="catalog">
            <p>上年利润(万元)</p>
            <p>{{requirementDetail.profitLastYear}}</p>
        </div>
        <div class="catalog">
            <p>上年资产额(万元)</p>
            <p>{{requirementDetail.assetsLastYear}}</p>
        </div>
        <div class="catalog">
            <p>上年负债额(万元)</p>
            <p>{{requirementDetail.liabilitiesLastYear}}</p>
        </div> 
    </div>
    <p v-if="requirementDetail.upMainCustomerList" class="title">上游主要客户</p>
    <div class="card" v-for="item in requirementDetail.upMainCustomerList" :key="item">
         <div class="catalog">
            <p>规模</p>
            <p>{{item.scale}}</p>
        </div>
        <div class="catalog">
            <p>主要客户名称</p>
            <p>{{item.majorClient}}</p>
        </div>
        <div class="catalog">
            <p>国家或地市</p>
            <p>{{item.addressAreaName}}</p>
        </div>
        <div class="catalog">
            <p>采购额业务占比</p>
            <p>{{item.purchaseAmtRatio}}</p>
        </div>
        <div class="catalog">
            <p>合作年限</p>
            <p>{{item.cooperYears}}</p>
        </div>        
    </div>
    <p v-if="requirementDetail.downMainCustomerList" class="title">下游主要客户</p>
    <div class="card" v-for="item in requirementDetail.downMainCustomerList" :key="item">
        <div class="catalog">
            <p>规模</p>
            <p>{{item.scale}}</p>
        </div>
        <div class="catalog">
            <p>主要客户名称</p>
            <p>{{item.majorClient}}</p>
        </div>
        <div class="catalog">
            <p>国家或地市</p>
            <p>{{item.addressAreaName}}</p>
        </div>
        <div class="catalog">
            <p>采购额业务占比</p>
            <p>{{item.purchaseAmtRatio}}</p>
        </div>
        <div class="catalog">
            <p>合作年限</p>
            <p>{{item.cooperYears}}</p>
        </div>        
    </div>
  </div>
</template>
<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { apiRequirementDetail,replaceGua,replaceCredit,replaceEnterProperty,replaceScale,replaceIndustry,replaceCertificate } from '../api/axios'
export default {
   setup() {
    let router = useRouter();
    let pId = router.currentRoute.value.query.requireId;
    const requirementDetail = ref({});
    apiRequirementDetail({requireId: pId}).then(res => {              
             if(res.code == '1'){
                 if(res.info.requirementDetail){
                     requirementDetail.value = res.info.requirementDetail;
                 }             
                
                // console.log(1111)
              }
              else {
                Toast(res.msg);
              }
    });
    return { 
        requirementDetail,
        replaceGua,
        replaceCredit,
        replaceEnterProperty,
        replaceScale,
        replaceIndustry,
        replaceCertificate
     };
  },
  methods:{
    goBack(){
        this.$router.go(-1);
    },
  }
}
</script>
<style lang="less" scoped>
.productContent {
    padding-top: 12.5vw;
    p.title {
        font-size: 5vw;
        font-weight: 600;
        margin: 4vw;
    }
    .card {
        padding: 2vw 4vw;
        .catalog {
            border-bottom: 1px solid rgba(0,0,0,.1);
            padding: 3vw 0;
            display: flex;
            p:first-child {
                width: 26vw;
                color: #666666;
                font-size: 4vw;
            }
            p:last-child {
                width: 55vw;
                font-size: 4vw;
                padding-left: 4vw;
                word-wrap: break-word;
            }
        }
        .catalog:last-child {
            border-bottom: 0;
        }
    }
    .edit {
        display: inline-block;
        background: linear-gradient(to right, #ff9900, #ff6700);
        color: #fff;
        font-size: 4.5vw;
        padding: 1vw 4vw;
        border-radius: 5vw;
    }
    
}
</style>
